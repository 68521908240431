import "../pages/home.css"
import { Link, useSearchParams } from "react-router-dom"
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { fetchCart, addToCart, itemInCart, isMobile, getZOHOImageURL, handleImageError, getAPIURL } from "../utils";

export default function SearchPage({setSuccessText, cartItems, setCartItems, setTireToEmail, setEmailText }) {
    const searchContainerRef = React.useRef();
    const [emptyResults, setEmptyResults] = React.useState(false);
    const [tires, setTires] = useState([]);
    const [quantity, setQuantity] = useState({});
    const fetchCartItems = useCallback(() => {
        async function doFetch() {
            await fetchCart(setCartItems);
        }
        doFetch();
    }, [setCartItems]);
    useEffect(() => fetchCartItems() , [fetchCartItems]);

    const [searchParams] = useSearchParams();

    const make = searchParams.get('make');
    const model = searchParams.get('model');
    const year = searchParams.get('year');
    const fixNone = value => value === 'None' ? 'none' : value;
    const sectionWidth = searchParams.get('sectionWidthMM');
    const aspectRatio = searchParams.get('aspectRatio');
    const seat = searchParams.get('seat');
    const category = searchParams.get('category');
    
    const mobile = isMobile();

    useEffect(() => {
        setEmptyResults(false);
        let params = {};
        const fieldValues = [make, model, year, sectionWidth, aspectRatio, seat, category];
        const fieldNames = ['make', 'model', 'year', 'sectionWidthMM', 'aspectRatio', 'seat', 'category'];

        for (let i = 0; i < fieldValues.length; i++) {
            if (fieldValues[i] != null) {
                params[fieldNames[i]] = fixNone(fieldValues[i]);
            }
        }

        axios.get(getAPIURL(`/api/tires/search`), { params })
            .then(response => {
                setTires(response.data);
                const emptyData = response.data.length === 0;
                const initialQuantities = {};
                response.data.forEach(tire => {
                    initialQuantities[tire.itemId] = 4;
                })
                setQuantity(initialQuantities);
                const {current} = searchContainerRef;
                if (current !== null && mobile && !emptyData){
                    current.scrollIntoView({behavior: "smooth"});
                }
                if (emptyData) {
                    setEmptyResults(true);
                }
            })
            .catch(error => {
                console.error("Failed to fetch products:", error);
            });
    }, [make, model, year, sectionWidth , aspectRatio, seat, category]);

    const handleQuantityChange = (itemId, event) => {
        setQuantity(prevQuantity => ({
            ...prevQuantity,
            [itemId]: event.target.value,
        }));
    };

    
    const oopsWidth = mobile ? 'w-9/10' : 'w-3/5';
    const oopsLeft = mobile ? '0px': '20%';

    const handleClick = (tire) => {
        const numQuantity = parseInt(quantity[tire.itemId]);
        if (parseFloat(tire.quantity) < 1) {
            setTireToEmail(tire);
        } else if (!isNaN(numQuantity) && numQuantity > tire.quantity) {
            setTireToEmail(tire);
            setEmailText("We do not have this amount of tire in stock. You can either reduce the amount, or place an order using the following form:");
        } else if (isNaN(numQuantity)){
            addToCart(tire.itemId, 1, setCartItems); 
            setSuccessText('Added to Cart!');
        } else {
            addToCart(tire.itemId, numQuantity, setCartItems);
            setSuccessText('Added to Cart!');
        }
    };

    const containerStyle = {height: '480px', width: '350px'}

    return <div>
        <section className="frontpage" style={{ backgroundColor: '#EDEDED'}}>
            {
                emptyResults &&
                <div className={oopsWidth + " center m-5 bg-white rounded overflow-hidden shadow-lg"} style={{position: 'relative', left: oopsLeft}}>
                    <p className="text-3xl p-10" style={{minHeight: '600px'}}>Oops! We don't seem to have any tires matching your search. Try searching again.</p>
                </div>
            }
            <section>
                <div className="flex flex-wrap justify-center items-start" ref={searchContainerRef}>
                    {tires.map(tire => (
                        <div key={tire.itemId} className="max-w-sm m-2 bg-white rounded overflow-hidden shadow-lg" style={containerStyle}>
                            <h2 className="w-full pt-5 pl-5 pr-5 center font-bold text-xl">
                                {tire.itemName}
                            </h2>
                            <p className="text-xl center">${tire.price}</p>
                            <p className="m-2 center">
                                <input
                                    type="number"
                                    value={quantity[tire.itemId]}
                                    onChange={(e) => handleQuantityChange(tire.itemId, e)}
                                    placeholder="1"
                                    className="w-16 h-10 px-2 rounded border focus:outline-none focus:border-blue-500 text-xl"
                                />
                                <button onClick={() => handleClick(tire)} className="h-10 px-2 bg-green-500 text-white font-bold rounded text-xl"
                                    disabled={itemInCart(tire.itemId, cartItems)}>
                                    {itemInCart(tire.itemId, cartItems) ? "Added!" : "Add to Cart"}
                                </button>
                            </p>
                            <div className='w-full flex justify-center'>
                                <Link to={`/ProductPage/${tire.itemId}`}>
                                    <img src={getZOHOImageURL(tire)} onError={handleImageError} alt="tire" className="searchPageTire"></img>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </section>
    </div>
}