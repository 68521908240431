import React from 'react';
import { Dialog, DialogTitle } from "@mui/material";

function EmailDialog({ tireToEmail, setTireToEmail, setEmailText, emailText }) {
  const emailHeader = emailText == null ?
    "We don't currently have that tire in stock, but you can order it using the following form:"
    : emailText;

  return <Dialog onClose={() => {setTireToEmail(null); setEmailText(null);}} open={tireToEmail != null} sx={{ zIndex: 2000000000 }}>
    <DialogTitle>Place an Order</DialogTitle>
    <div style={{marginLeft: '20px', marginRight: '20px'}}>
      <p style={{marginBottom: '10px'}}>
        { emailHeader } <a href="https://docs.google.com/forms/d/e/1FAIpQLSevUBevbannDsx0dq-Qf7wMYdAZXHyH-uB-YHvoFwg1oGkkpg/viewform" style={{ color: 'blue', textDecoration: 'underline'}}>Order Form</a>
      </p>
    </div>
  </Dialog>
}

export default EmailDialog;
