import { Link } from "react-router-dom"
import { Button } from "@mui/material";
import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchCart, getToken, getZOHOImageURL, handleImageError, isMobile, getAPIURL  } from "../utils";

export default function CartPage({ cartItems, setCartItems, handleErrorMessage }) {
  const mobile = isMobile();
  const calcSpacing = (desktopSpace, mobileSpacing=12) => mobile ? mobileSpacing : desktopSpace;
  const totalPrice = cartItems ? cartItems.reduce((total, item) => total + (parseInt(item.quantity) * parseFloat(item.price)), 0) : 0;
  const fetchCartItems = useCallback(() => {
    async function doFetch() {
      await fetchCart(setCartItems);
    }
    doFetch();
  }, [setCartItems]);
  useEffect(() => fetchCartItems(), [fetchCartItems]);
  
  const UpdateCartItem = (cartItem, shouldIncrease) => {
    async function UpdateCartAsync() {
      const token = await getToken();
      const data = {
        itemId: cartItem.itemId,
        quantity: cartItem.quantity + (shouldIncrease ? 1 : -1),
        access_token: token 
      };
      axios.post(getAPIURL(`/api/cart/update`), data)
        .then(() => {
          // handle the response by updating the cart items
          fetchCartItems();
          handleErrorMessage(null);
        })
        .catch(error => {
          handleErrorMessage(error);
        })
    }
    UpdateCartAsync();
  }
  
  const DeleteCartItem = (cartItem) => {
    async function DeleteCartAsync() {
      const token = await getToken();
      const data = {
        itemId: cartItem.itemId,
        access_token: token 
      };
      axios.post(getAPIURL(`/api/cart/delete`), data)
        .then(() => {
          // handle the response by updating the cart items
          fetchCartItems();
          handleErrorMessage(null);
        })
        .catch(error => {
          handleErrorMessage(error);
        })
    }
    DeleteCartAsync();
  }
    
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const gridClassStr = (mobile ? 'grid-cols-2' : 'grid-cols-12') + ` grid gap-4 bg-white border border-gray-200 rounded-lg shadow m-5`;
  const centerStyles = mobile ? {justifyContent: "center", display: 'flex'} : {};

  return (
    <div className="container mx-auto mt-4 shadow-2xl">
        <h1 className="text-3xl font-bold p-5">Shopping Cart</h1>
        <div style={{minHeight: '500px'}}>
            {cartItems && cartItems.map((item) => (
              <div className={gridClassStr} key={item.itemId}>
                <div className={"col-span-"+calcSpacing(3, 4)} style={mobile ? {justifyContent: "center"} : {}}>
                    <img className={"object-contain h-48 w-96 " + (mobile ? 'mt-4' : 'ml-4')} src={getZOHOImageURL(item)} alt="" onError={handleImageError}/>
                </div>
                <div className={"col-span-4 flex items-center"}>
                    <div className={`block max-w-sm ${mobile ? 'pl-6 pr-6' : 'p-6'}`}>
                        {
                          (parseFloat(item.inventory_quantity) === 0.0) &&
                          <p className="font-bold text-red-500">This item is no longer in stock. <br/>Please remove it.</p>
                        }
                        {
                          (parseFloat(item.inventory_quantity) > 0 && parseFloat(item.quantity) > parseFloat(item.inventory_quantity)) &&
                          <p className="font-bold text-red-500">You have too many of this item. <br/>There are only {parseFloat(item.inventory_quantity)} in stock.</p>
                        }
                        <h5 className="mb-2 text-2xl text-gray-900 font-bold tracking-tight">{item.itemName}</h5>
                        <p className="font-normal text-gray-700">{item.description}</p>
                    </div>
                </div>
                <div className={`text-gray-900 col-span-${calcSpacing(4,4)} flex items-center`} style={mobile ? {justifyContent: "center", display: 'grid'} : {}}>
                    <p className="m-4 text-2xl font-bold tracking-tight text-gray-900" style={centerStyles} >{formatter.format(item.price)}</p>
                    <div style={{display: mobile ? 'flex' : 'block', minWidth: 'max-content'}}>
                      <p className="text-xl font-normal tracking-tight" style={mobile ? {justifyContent: "center", display: 'flex'} : {display: 'inline-flex'}}>Quantity: {item.quantity}</p>
                      <div className="ml-4 flex flex-col rounded-md shadow-sm" style={mobile ? {} : {display: 'inline-flex'}}>
                        <button onClick={() => UpdateCartItem(item, true)} className="text-gray-900 hover:bg-gray-300">
                            <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
                            </svg>
                        </button>
                        <button onClick={() => UpdateCartItem(item, false)} className="text-gray-900 hover:bg-gray-300">
                          <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div style={centerStyles} className={(mobile ? 'mb-4' : 'ml-4')}>
                      <button onClick={() => DeleteCartItem(item)} className={"bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"} style={{width: 'max-content'}}>
                        <DeleteIcon/>
                      </button>
                    </div>
                </div>
            </div>))
            }
        </div>
        <div className="relative p-8 mb-4">
            <p className="text-xl mb-4">
                Total:<span className="font-bold">${totalPrice.toFixed(2)}</span>
            </p>
            <Button variant="contained" style={{backgroundColor: '#C9D2D7', color: 'black'}}>   
                <Link to="/ShipmentForm">
                    Continue To Shipping
                </Link>
            </Button>
        </div>
    </div>
  );
}
