import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { getToken, isMobile, getAPIURL } from "../utils";

export default function ShipmentForm({ handleErrorMessage }) {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [addressLine1, setAddressLine1] = React.useState('');
    const [addressLine2, setAddressLine2] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [localPickup, setLocalPickup] = React.useState(false);
    const [wholesale, setWholesale] = React.useState(false);
    const [wholesaleCode, setWholesaleCode] = React.useState('');

    const toggleLocalPickup = (e) => {
        setLocalPickup(e.target.checked);
    }

    const toggleWholesale = (e) => {
        setWholesale(e.target.checked);
    }

    const handleCheckout = async () => {
        try {
            let shippingData = {
                name: firstName + " " + lastName,
                addressLines: [addressLine1, addressLine2],
                city: city,
                state_code: state,
                zip_code: zipCode,
                access_token: await getToken(),
                is_local: localPickup
            };
            if (wholesaleCode != null && wholesaleCode.trim() !== '') {
                shippingData = {
                    wholesale_code: wholesaleCode,
                    access_token: await getToken(),
                    is_local: false
                };
            }
            const response = await axios.post(getAPIURL('/api/checkout'), shippingData);
            handleErrorMessage(null);
            window.location.replace(response.data.url);
        } catch (error) {
            handleErrorMessage(error);
            console.error('Error processing shipping or payment:', error);
        }
    }

    const fieldLabels = ["First Name", "Last Name", "Address Line 1", "Address Line 2", "City"];
    const fieldValues = [firstName, lastName, addressLine1, addressLine2, city];
    const fieldSetters = [setFirstName, setLastName, setAddressLine1, setAddressLine2, setCity];
    const inputWidths = [70, 70, 70, 70, 100, 100, 100, 70];
    const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

    return (
        <div className="m-10 flex flex-col items-center justify-center">
            <div className={"p-6 w-" + (isMobile() ? "full" : "1/3") + " shadow-md rounded-md"}>
                <h2 className="text-2xl font-bold text-center">Shipping Address</h2>
                {
                    <label className="flex items-center cursor-pointer w-100">
                        <input type="checkbox" value="" className="sr-only peer" id='localPickup'
                            onChange={(e) => toggleWholesale(e)}
                        ></input>
                        <div className="ml-auto relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                        ></div>
                        <span className="mr-auto ms-3 font-medium text-gray-900">Wholesale Order</span>
                    </label>
                }
                {
                    wholesale &&
                    <div className="mb-4 w-full">
                        <label className="block text-gray-full">Wholesale Code</label>
                        <input style={{width: "50%"}} type="text" className={"mt-1 px-4 py-2 border rounded-md"} value={wholesaleCode} onChange={e => setWholesaleCode(e.target.value)} />
                    </div>
                }
                {
                    !wholesale && fieldLabels.map((fieldLabel, index) => {
                        return (
                            <React.Fragment key={index}>
                                { (!localPickup || (index < 2)) &&
                                    <div className="mb-4 w-full">
                                        <label className="block text-gray-full">{fieldLabel}</label>
                                        <input style={{width: inputWidths[index] + "%"}} type="text" className={"mt-1 px-4 py-2 border rounded-md"} value={fieldValues[index]} onChange={e => fieldSetters[index](e.target.value)} />
                                    </div>
                                }
                                {
                                    index === 1 && 
                                    <label className="flex items-center cursor-pointer w-100">
                                        <input type="checkbox" value="" className="sr-only peer" id='localPickup'
                                        onChange={(e) => toggleLocalPickup(e)}
                                        ></input>
                                        <div className="ml-auto relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                                        ></div>
                                        <span className="mr-auto ms-3 font-medium text-gray-900">Local Pickup</span>
                                    </label>
                                }
                            </React.Fragment>
                        );
                    })
                }
                {
                    (!localPickup && !wholesale) &&
                    <React.Fragment>
                        <div className="mb-4">
                            <label className="block text-gray-full">State</label>
                            <select className="w-1/3 mt-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
                                value={state} onChange={e => setState(e.target.value)}>
                                <option value="DEFAULT">Choose a state</option>
                                {states.map((state) => {
                                    return <option key={state} value={state}>{state}</option>
                                })}
                            </select>
                        </div>
                        <div className="mb-4 w-full">
                            <label className="block text-gray-full">Zip Code</label>
                            <input type="text" className={"w-1/3 mt-1 px-4 py-2 border rounded-md"} value={zipCode} onChange={e => setZipCode(e.target.value)} />
                        </div>
                    </React.Fragment>
                }
                <Button variant="contained" style={{backgroundColor: '#C9D2D7', color: 'black'}} onClick={handleCheckout}>   
                    Checkout
                </Button>
            </div>
        </div>
    );
}
