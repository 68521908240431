import React from 'react';
import { isMobile } from '../utils';

export default function TermsAndConditions() {
    const mobile = isMobile();
    const cardClasses = mobile ? "h-full p-6 w-full shadow-md rounded-md" : "h-full p-6 w-1/2 shadow-md rounded-md";
    const termsAndConditions = [
        ['ORDERS', 'To purchase, place your order securely online on our web site. You may also call us at 1-443-617-6621. Some products have limited availability, and all prices are subject to change without prior notice.'],
        ['SALES TAXES', 'We collect and remit sales tax based on your state and/or local requirements.'],
        ['PAYMENT FOR ORDERS', 'Eclectic Tire Co. accepts most major credit cards. Sometimes credit card companies require your order to be sent to the address your charge card statement is sent. We reserve the right to ship your merchandise to the address the credit card company has on file. Orders paid for by American Express will ONLY be shipped to the address they have for your account. We do not accept Cash on Delivery (COD) shipments. For customers who wish to pay by check, we recommend that you send a certified check or money order or be willing to wait up to 21 days for your check to clear before shipment is initiated.'],
        ['TIRE & WHEEL BUNDLES', 'Eclectic Tire Co. offers services of precision mounting and balancing (on certain sizes) when you purchase tires and wheels as a package from us. If there is a fee for mounting and balancing, your credit card will be charged in full prior to mounting and balancing whether the product is shipped to you or picked up at our location. Once a tire and wheel are mounted, the status of these products has changed. All wheel manufacturers have a NO RETURN policy after a wheel has been mounted. If you have ordered products to pick up at our location in Maryland, it is your responsibility to pick up your items per agreement.'],
        ['RETURNS', 'We will return or exchange merchandise still in new condition within 90 days of purchase (or shipment.) Tires must be new, with no wear and never mounted. Your refund will be returned to the original method of payment. Before returning an item, please call us at 1-443-617-6621 to authorize. ETC reserves the right to request photos of items being returned prior to refund authorization. Returned merchandise must be accompanied by a copy of your invoice.<br><br>All returns are subject to a 10% restocking fee. No returns after 90 days. We do not accept C.O.D. shipments on returned items.'],
        ['WARRANTY', 'Our tires are guaranteed to be free from defects in workmanship and materials for the life of the tread. Adjustment is made on a prorated basis (you pay for tread wear used). We are not responsible for tire and tube failure due to improper mounting, mechanical failure, or improper alignment of the vehicle. Eclectic Tire Co. does not offer any guarantee of fitment for any particular purpose and limits its liability to the purchase price of the item. All tires and tubes should be installed by properly trained individuals. Dimensions for tires listed are not scientifically measured and may vary depending on rim widths and inflation. Warranty returns will be processed through the manufacturer of the tire.'],
        ['WEB LISTINGS', 'Pricing and descriptions are subject to change. Photos shown are for reference only and may not reflect the specific product.'],
        ['DAMAGE CLAIMS', 'It is recommended to immediately inspect the contents of your shipment. Immediately notify the carrier if damage has occurred. (UPS claim form File a Claim | UPS - United States) Boxed merchandise must be kept in the original shipping carton for inspection. DO NOT return damaged items to Eclectic Tire Co. until inspected by UPS. The package must be inspected at the point of delivery. It is the responsibility of the carrier to deliver the merchandise in good condition. In the unlikely event that you receive a package that has been opened or damaged, make sure the driver notes this fact on their delivery sheet..'],
        ['SHIPPING INSTRUCTIONS', 'All sales are shipped from our location in Maryland. We ship via UPS. We do our best to estimate shipping at the time of purchase. If there are any other unforeseen shipping charges, they will be added to your order. Insurance will be added to all packages valued over $100.00 ($1.00 per $100). This supplemental insurance cost will show as a separate charge on your payment card. We will do our best to get you the best rate!'],
        ['EXPORT CUSTOMERS', 'Eclectic Tire Co. is not responsible for additional fees such as brokerage fees, taxes, customs, or duties.']
    ];
    return (
        <div className="m-10 flex flex-col items-center justify-center">
            <div className={cardClasses}>
                <h2 className="text-2xl font-bold text-center">Terms And Conditions</h2>
                {
                    termsAndConditions.map(arr => {
                        return <React.Fragment key={arr[0]}>
                            <h3 className="text-xl font-bold mt-1 mb-1">{arr[0]}</h3>
                            <p className="text-l text-gray-500">{arr[1]}</p>
                        </React.Fragment>
                    }) 
                }
            </div>
        </div>
    );
};