import "./home.css"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetchCart, itemInCart, addToCart, getZOHOImageURL, handleImageError, isMobile, getAPIURL } from '../utils';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ProductPage({ setTireToEmail, setSuccessText }) {
    let { itemId } = useParams();  // get the item ID from route parameters
    const [product, setProduct] = useState({});  // initialize product state
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                await fetchCart(setCartItems);
                const response = await axios.get(getAPIURL(`/api/tires/part/${itemId}`));
                setProduct(response.data);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [itemId]);  // refetch when item ID changes

    const labels = ['Category', 'Speed Rating', 'Weight (lbs.)', 'Ext. Diameter (in.)', 'Section Width (in.)', 'Seat', 'Part ID', 'CAI'];
    const values = [product.category, product.speedRating, product.weightLbs, product.lengthIN, product.heightIN, product.seat, product.itemId, product.cai];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const price = formatter.format(product.price);
    let description = product.description;
    if (description != null) {
        if (description.startsWith('"')) {
            description = description.substring(1);
        }
        if (description.endsWith('"')) {
            description = description.substring(0, description.length - 1);
        }
    }
    const mobile = isMobile();
    const calcSpacing = (desktopSpace, mobileSpacing=12) => mobile ? mobileSpacing : desktopSpace;
    const inCart = itemInCart(product.itemId, cartItems);

    const tryToAdd = () => {
        if (parseFloat(product.quantity) < 1) {
            setTireToEmail(product);
        } else {
            addToCart(product.itemId, 1, setCartItems);
            setSuccessText('Added to Cart!');
        }
    }

    return <div>
        <section className="frontpage" style={{ backgroundColor: '#EDEDED'}}>
            <section className="page">
                <section className="middlebox">
                <Grid container spacing={2}>
                    <Grid container item xs={calcSpacing(3)}>
                        <img src={getZOHOImageURL(product)} className="yellow-border" onError={handleImageError}
                             style={mobile ? {width: '50%', height: 'auto', position: 'relative', left: '25%'} : {}}/>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                    <Grid item xs={calcSpacing(7)}>
                        <Stack sx={{ position: 'relative', left: '5%', width: '90%' }} >
                            <TableContainer component={Paper} className="yellow-border">
                                {!mobile &&
                                    <React.Fragment>
                                        <Stack style={{width: '100%'}}>
                                            <Typography variant="h4" gutterBottom className="pl-4">
                                                {product.itemName}
                                            </Typography>
                                            <Typography variant="h5" gutterBottom className="pl-4 pr-4">
                                                {product.description}
                                            </Typography>
                                        </Stack>
                                        <Table size="large">
                                            <TableBody>
                                                {
                                                    labels.map((label, index) => (
                                                        <TableRow key={label}>
                                                            <TableCell component="th" scope="row" sx={{fontSize: '15pt'}}>
                                                                <b>{label}</b>
                                                            </TableCell>
                                                            <TableCell scope="row" sx={{fontSize: '15pt'}}>
                                                                {values[index]}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                }
                                {
                                    mobile &&
                                    <React.Fragment>
                                        <p className="text-xl font-bold center mb-2">{product.itemName}</p>
                                        <p className="text-l text-gray-900 center mb-2">{description}</p>
                                        <table className="table-fixed">
                                            <tbody>
                                                {
                                                    labels.map((label, index) => (
                                                        <tr key={index}>
                                                            <td className="font-bold text-gray-700 float-left">{label}</td>
                                                            <td className="text-gray-700 float-right">{values[index]}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                }
                            </TableContainer>
                        </Stack>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid container item xs={calcSpacing(2)} sx={{ mr: "-1px" }}>
                        <Paper sx={{position: 'relative', left:'7.5%', width: '85%', height: 'fit-content'}}>
                            <Stack alignItems="center" style={{width: '100%'}}>
                                <Typography sx={{p: 1}} variant="h4">
                                    {price}
                                </Typography>
                                <Button variant="contained" style={{backgroundColor: '#C9D2D7', width: '100%'}}
                                    disabled={inCart} onClick={tryToAdd}>
                                    {inCart ? "Added!" : "Add to Cart"}
                                </Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                </section>
            </section>
        </section>
    </div>
}