import "./home.css"
import { useSearchParams } from "react-router-dom"
import React from 'react';

import { getImageURL, isMobile, useWindowDimensions } from "../utils"

export default function Home({handleErrorMessage}) {
    const [searchParams] = useSearchParams();
    const msg = searchParams.get('msg');
    if (msg != null) {
        handleErrorMessage(msg);
    }

    const mobile = isMobile();
    const imageNames = ['slide1', 'slide2', 'slide3', 'slide4', 'slide5'];
    const carouselContainerRef = React.useRef(null);
    const { width, height } = useWindowDimensions();
    let imageHeight, imageWidth;
    if (mobile) {
        imageWidth = width * 0.98;
        // imageHeight = (imageWidth * (965 / 2222));
        imageHeight = (imageWidth * (2960 / 4564));
    } else {
        imageHeight = height / 2;
        imageWidth = (imageHeight * (4564 / 2960));
    }
    const image1Height =  (imageWidth * (749 / 2222));

    // const [ticking, setTicking] = React.useState(true);
    const [count, setCount] = React.useState(0);
    const TICKS_PER_SECOND = 20;
    const IMAGE_UPDATE_INTERVAL_SECS = 6;
    //const TRANSITION_DURATION_SECS = 1/2;
    const secondsCount = count / TICKS_PER_SECOND;
    const getOpacity = (index) => {
        const mainRemainder = secondsCount % (IMAGE_UPDATE_INTERVAL_SECS * imageNames.length);
        const isCurrentImage = (index === Math.floor(mainRemainder / IMAGE_UPDATE_INTERVAL_SECS));
        /*const isNextImage = (index === (1 + Math.floor(mainRemainder / IMAGE_UPDATE_INTERVAL_SECS)) % imageNames.length);
        let currProgress = 0.0;
        if (secondsCount % IMAGE_UPDATE_INTERVAL_SECS >= IMAGE_UPDATE_INTERVAL_SECS - (TRANSITION_DURATION_SECS / 2)) {
            currProgress = (secondsCount % IMAGE_UPDATE_INTERVAL_SECS - (IMAGE_UPDATE_INTERVAL_SECS - (TRANSITION_DURATION_SECS / 2))) / (TRANSITION_DURATION_SECS / 4);
        } else if (secondsCount % IMAGE_UPDATE_INTERVAL_SECS <= TRANSITION_DURATION_SECS / 2) {
            currProgress = 0.5 + (secondsCount % IMAGE_UPDATE_INTERVAL_SECS) / (TRANSITION_DURATION_SECS / 2);
        }
        console.log(secondsCount, index, isCurrentImage, isNextImage, currProgress);*/
        if (isCurrentImage) {
            return 1.0
            //return currProgress === 0.0 ? 1.0 : 1.0 - currProgress;
        } /*else if (isNextImage) {
            return currProgress === 0.0 ? 0.0 : currProgress;
        }*/
        return 0.0;
    }
    // every 5 milliseconds = 200 for one second
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setCount(count + 1);
            //if (ticking) {
                
                // console.log(calculateOpacity());
            //}
        }, Math.floor(1000 / TICKS_PER_SECOND));
        return () => clearTimeout(timer)
    }, [count])

    const topImageStyles = mobile ? {
        width: "98%", border: '3px outset #000', borderRadius: '12px'
    } : {left: '0px', width: imageWidth + 'px', height: image1Height + 'px', border: '3px outset #000', borderRadius: '12px'};
    const carouselImageStyle = (index) => {
        const toReturn = {
            position: 'relative',
            width: imageWidth,
            height: imageHeight + 'px',
            top: (-index * (imageHeight + 20)) + 'px',
            left: mobile ? '1%' : '0px',
            opacity: getOpacity(index)
        }
        return toReturn;
    };
    return (
        <div>
            <section className="frontpage" style={{ backgroundColor: '#EDEDED'}}>
                <div className="flex justify-center">
                    <img src={getImageURL("slidehead1.png")} className="image" style={topImageStyles}/>
                </div>
                
                <div className="flex justify-center">
                    <div className="mb-5" ref={carouselContainerRef} style={{height: imageHeight + 'px'}}>
                        {
                            imageNames.map((imageName, index) => {
                                return <img className="image" src={getImageURL(`${imageName}.png`)} style={carouselImageStyle(index)} key={imageName}/>
                            })
                        }  
                    </div>
                </div>
                <div className="reachOutContainer align-center">
                    <article className="prose lg:prose-xl w-4/5" style={{position: 'relative', left: '10%'}}>
                        <h1>
                            Need Mounting?
                        </h1>
                        <p>
                            To give your automobile the proper ride it deserves, ETC is proud to provide tires for your vehicle, as well as tire mounting at our location in New Windsor Maryland. Peruse the site to find the proper tires for your individual collectible or contact us by one of the methods provided below for guidance. We are honored to serve you and your car's needs.
                        </p>
                        <br/>
                        <h3>
                            Reach Out to Schedule: <br/>443-671-6621
                        </h3>
                    </article>
                </div>
            </section>
        </div>
    );
}

