import React from 'react';
import Button from '@mui/material/Button';
import { getImageURL, isMobile } from '../utils';

export default function Catalog() {
    return (
        <div className="m-10 flex flex-col items-center justify-center">
            <div className={"h-full p-6 w-" + (isMobile() ? "full" : "1/3") + " shadow-md rounded-md"}>
                <h2 className="text-2xl font-bold text-center">Full Tire Catalog</h2>
                <img src={getImageURL('catalog_preview.jpg')} className="w-2/3" style={{position: 'relative', left: '16.66666%'}}></img>
                <div className="text-center mt-10">
                    <Button variant="contained" style={{backgroundColor: '#C9D2D7', color: 'black'}}>
                        <a href="https://cxf-prod.azureedge.net/b2c-experience-production/attachments/cltmuv6ak08gn01oihavwgfhk-michelin-brochure-retro-gb-v3.pdf">
                            Download Full Catalog <br/>(16.5 MB)
                        </a>
                    </Button>
                </div>
            </div>
        </div>
    );
}
