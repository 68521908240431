import React from 'react';
import { isMobile } from '../utils';

export default function FAQ() {
    const mobile = isMobile();
    const faq = [
        ['How do I choose the right tire for my car?', 'Use the search function to search by make or model. If you are not sure, you may also contact us for assistance.'],
        ['Do the tires include rims?', 'Tire purchases include the tires only. If you are local and would like rims, please contact us about separately purchasing rims. We offer mounting, balance, and installation.'],
        ['How do I place an order?', 'Select the tires you would like to purchase using the search options at the top of the website. Add your desired quantity to the cart by clicking the "add to cart" button. Once you have made your selections, navigate to the cart, and follow the prompts to check out. If you prefer to place your order via phone, please contact us.'],
        ['How can I check the status of an order?', 'When you place your order, you will receive an emailed receipt which confirms we\'ve received your order successfully. You will receive another email with your tracking number once your order has been processed. You may use that tracking number to locate your order status with the shipping carrier. '],
        ['The tires I need for my vehicle are not in stock; can I preorder them?', 'Please contact us to determine if this option is available.'],
        ['What options do I have for payment?', 'We accept cash (locally), debit and most credit cards.'],
        ['When will my credit card be charged?', 'The full price of the tires and any applicable taxes or shipping is charged at the time of purchase.'],
        ['What are my shipping options?', 'If you are local to central Maryland, please contact us to make an appointment to pick up your tires.', 'You may have your order delivered to the address you provide. We are unable to ship to PO Boxes. Note that some credit card companies require shipments to be sent to the address associated with the credit card. All our shipments will use UPS.', 'You may have your order shipped to our location in New Windsor Maryland If you would like to have your tires mounted. You must contact us when you place your order to coordinate mounting and balancing service.'],
        ['Do I need to be present to accept delivery?', 'Generally, no. Shipments will be delivered and left at your provided address in accordance with the carrier\'s standard operations.'],
        ['Who do I contact about having my tires mounted?', 'Eclectic Tire Co offers precision mounting and balancing of your tires. You will need to provide your desired wheels. Please contact us directly to schedule this service.'],
        ['How much is the tire mounting service?', 'We are please to offer precision mounting and balancing of your tires tailored to your vehicle\'s exact specifications.', 'We quality inspect each wheel prior to mounting and balancing.', 'The fee for mounting service may vary. Determining factors include whether your wheels are provided by you or shipped to us from a partnering distributor. Please contact us for details.'],
        ['Must I have my tires balanced?', 'Proper balancing is a critical step for your vehicle to optimally perform. Unbalanced tires can cause vibrations which negatively affect your vehicles drivability.']
    ];
    const containerClasses = mobile ? "text-left border-t border-gray-200 mx-auto" : "text-left border-t border-gray-200 mx-auto w-4/5";
    const cardClasses = mobile ? "h-full p-6 w-full shadow-md rounded-md" : "h-full p-6 w-2/3 shadow-md rounded-md";
    return (
        <div className="m-10 flex flex-col items-center justify-center">
            <div className={cardClasses}>
                <h2 className="text-2xl font-bold text-center">Frequently Asked Questions</h2>
                <div className={containerClasses}>
                {
                    faq.map((textArr, index) => {
                        const question = textArr[0];
                        const answers = textArr.slice(1);
                        return <div key={index} className="mt-4 mb-4">
                                <h3 className="flex items-center text-lg font-medium text-gray-900">
                                    <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    {question}
                                </h3>
                                <ul className="text-gray-500 list-disc">
                                    {
                                        answers.map(answer => {return <li className="ml-10">{answer}</li>;})
                                    }
                                </ul>
                            </div>;
                        })
                } 
                </div>
            </div>
        </div>
    );
}
